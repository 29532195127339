import { useTranslation } from 'next-i18next'
import { IPageFields, IResourceFields } from '../../../@types/generated/contentful'
import { ContentTypes } from '../../../api/contentTypeIds'
import { SimpleEntry } from '../../../api/types'
import useGetEntry from '../../../hooks/requests/useGetEntry'
import AppBarBackButton from '../../AppBarBackButton'
import AuthLayout from '../../AuthLayout'
import BodyPadding from '../../BodyPadding'
import CamfedAppBar from '../../CamfedAppBar'
import { Locales } from '../../Languages/constants'
import { IScreenProps } from '../../Navigation/types'
import RelatedResources from '../../RelatedResources'
import RequestLoadingHandler from '../../RequestLoadingHandler'
import RichText from '../../RichText'

interface IPageProps extends IScreenProps { }

export default function Page(props: IPageProps): JSX.Element {
  const { id, navigator } = props

  const { i18n } = useTranslation()
  const lang = i18n.language
  const { data, error, fetchStatus, isError, isLoading, isSuccess } = useGetEntry<IPageFields>(ContentTypes.page, id, {
    locale: Locales.find((l) => l.code === lang)?.apiCode,
  })

  const relatedResources: SimpleEntry<IResourceFields>[] | undefined = data?.fields?.relatedResources as
    | SimpleEntry<IResourceFields>[]
    | undefined

  return (
    <AuthLayout
      appBar={
        <CamfedAppBar
          centralText={data && 'fields' in data ? data?.fields?.title : ''}
          leftButton={<AppBarBackButton onClick={navigator.pop} />}
          prominent={true}
        />
      }
    >
      <BodyPadding>
        <RequestLoadingHandler
          error={error}
          fetchStatus={fetchStatus}
          isError={isError}
          isLoading={isLoading}
          isSuccess={isSuccess}
        >
          <RichText document={data?.fields?.body} />
          <RelatedResources className="mt-4" relatedResources={relatedResources} />
        </RequestLoadingHandler>
      </BodyPadding>
    </AuthLayout>
  )
}
