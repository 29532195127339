import { toJS } from 'mobx';
import { types } from 'mobx-state-tree';
import { IAccountLocalData } from '../api/types';

const Account = types
  .model({
    localAccount: types.maybe(types.frozen<IAccountLocalData>()),
    previousEmail: types.maybe(types.string),
    pendingEmail: types.maybe(types.union(types.string, types.null)),
    previousPhone: types.maybe(types.string),
    pendingPhone: types.maybe(types.union(types.string, types.null)),
  })
  .actions((self) => ({
    enqueueForUpdate(account: IAccountLocalData) {
      const data = toJS(self.localAccount);
      self.localAccount = { ...data, ...account };
    },
    didUpdateAccount() {
      self.localAccount = undefined;
    },
    setPreviousPhone(phone: string) {
      self.previousPhone = phone;
    },
    setPendingPhone(phone: string | null) {
      self.pendingPhone = phone;
    },
    setPreviousEmail(email: string) {
      self.previousEmail = email;
    },
    setPendingEmail(email: string | null) {
      self.pendingEmail = email;
    },
  }));

export default Account;