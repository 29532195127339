import { useEffect, useCallback, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { reaction } from 'mobx'
import useMst from '../../models/useMst'
import { updateDetailsRoute } from '../../constants'
import useConfirmCheck from '../Auth/useConfirmCheck'
import { useRouter } from 'next/router'

interface UpdateCheckWrapperProps {
  children: ReactNode
}

const UpdateCheckWrapper = observer(({ children }: UpdateCheckWrapperProps) => {
  const { router } = useMst()
  const nextRouter = useRouter()

  if (nextRouter.pathname === '/auth/logout') {
    return <>{children}</>
  }

  const { data: confirmCheckData, isLoading, isError, refetch } = useConfirmCheck()

  const redirectToUpdateDetails = useCallback(async () => {
    await router.pushRouteOrBringToTop(updateDetailsRoute)
  }, [router])

  // Reaction to observe changes in `router.topRoute`
  useEffect(() => {
    const disposeReaction = reaction(
      () => router.topRoute, // Observe the active route
      () => {
        refetch() // Trigger the update check
      }
    )

    return () => {
      disposeReaction() // Clean up the reaction
    }
  }, [router, refetch])

  useEffect(() => {
    if (!isLoading && !isError && confirmCheckData !== 'no_content') {
      redirectToUpdateDetails()
    }
  }, [isLoading, isError, confirmCheckData, redirectToUpdateDetails])

  return <>{children}</>
})

export default UpdateCheckWrapper