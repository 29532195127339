import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import useMst from '../../../../models/useMst';

interface DeleteBtecUnitLogParams {
  applicationId: string;
  unitId: string;
  logId: string;
}

const deleteBtecUnitLog = async ({ applicationId, unitId, logId }: DeleteBtecUnitLogParams) => {
  const response = await axios.delete(`/api/btec/applications/${applicationId}/units/${unitId}/logs/${logId}`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
  return response.data;
};

interface UseDeleteBtecUnitLogProps {
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
}

export default function useDeleteBtecUnitLog({ onSuccess, onError }: UseDeleteBtecUnitLogProps = {}) {
  const { messages } = useMst();
  const queryClient = useQueryClient();

  return useMutation(deleteBtecUnitLog, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['btecUnitLogs', variables.applicationId, variables.unitId]);
      queryClient.invalidateQueries(['btecApplication', variables.applicationId]);
      messages.showMessage('Log entry deleted successfully!', 'success');
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (error, variables, context) => {
      const { applicationId, unitId } = variables;
      // Check if this specific 500 error is the one we're encountering.
      if (
        axios.isAxiosError(error) &&
        error.response?.status === 500 &&
        error.response?.data?.message?.includes('Attempt to read property "status" on null')
      ) {
        // Handle as a success, since the log is likely deleted.
        queryClient.invalidateQueries(['btecUnitLogs', applicationId, unitId]);
        queryClient.invalidateQueries(['btecApplication', applicationId]);
        messages.showMessage('Log entry deleted successfully!', 'success');
      } else {
        // Default error handling
        queryClient.invalidateQueries(['btecUnitLogs', applicationId, unitId]);
        queryClient.invalidateQueries(['btecApplication', applicationId]);
        messages.showMessage('Failed to delete log entry. Please try again.', 'error');
        if (onError) {
          onError(error);
        }
      }
    },
    
  });
}