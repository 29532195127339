import React, { useState, useEffect } from 'react'
import { Box, Button, Checkbox, Hidden, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, CircularProgress } from "@mui/material"
import InfoIcon from '@mui/icons-material/Info'
import AuthLayout from "../../AuthLayout"
import CamfedAppBar from "../../CamfedAppBar"
import { useTranslation } from "react-i18next"
import { observer } from "mobx-react-lite"
import AppBarBackButton from "../../AppBarBackButton"
import { INavigator } from "../../Navigation/types"
import useNavigator from "../../Navigation/useNavigator"
import useGetBtecApplications from './hooks/useGetBtecApplications'
import useGetBtecOptionalUnits from './hooks/useGetBtecOptionalUnits'
import useSubmitOptionalUnits from './hooks/useSubmitOptionalUnits'
import useMst from '../../../models/useMst'
import { btecCourseManagementRoute } from '../../../constants'

interface OptionalUnit {
  id: number
  btec_course_id: number
  title: string
  description: string
  is_mandatory: boolean
  learning_hours: number
  position: number
  selected: boolean
}

interface UserOptionalUnit {
  id: string
  unit: number
  name: string
  summary: string
  status: string
}

interface BtecApplication {
  id: string
}

interface ErrorWithMessage {
  message: string
}

const BTECOptionalModulesPage = (props: { data?: any }) => {
  const navigator: INavigator = useNavigator()
  const { t } = useTranslation('btec')
  const title = t('btecOptionalModules')
  const { router } = useMst()
  const { replaceTopRoute } = router
  const { userOptionalUnits } = props.data

  const { data: applications, error: applicationsError, isLoading: applicationsLoading, isError: applicationsIsError } = useGetBtecApplications(true)

  const getLastId = (applications: BtecApplication[]): string | null => {
    if (applications && applications.length > 0) {
      return applications[applications.length - 1].id
    }
    return null
  }

  const applicationId = getLastId(applications ?? [])
  const { data: fetchedOptionalUnits, error: optionalUnitsError, isLoading: optionalUnitsLoading, isError: optionalUnitsIsError } = useGetBtecOptionalUnits(applicationId ?? '', !!applicationId)

  const [optionalUnits, setOptionalUnits] = useState<OptionalUnit[]>([])
  const [totalLearningHours, setTotalLearningHours] = useState(0)
  const [selectedCount, setSelectedCount] = useState(0)

  useEffect(() => {
    if (fetchedOptionalUnits) {
      const unitsWithSelection = fetchedOptionalUnits.map(unit => ({
        ...unit,
        selected: userOptionalUnits ? userOptionalUnits.some((userUnit: UserOptionalUnit) => userUnit.unit === unit.id) : false,
        learning_hours: unit.learning_hours ?? 0
      }))
      setOptionalUnits(unitsWithSelection)
    }
  }, [fetchedOptionalUnits, userOptionalUnits])

  useEffect(() => {
    const total = optionalUnits.filter(unit => unit.selected).reduce((acc, unit) => acc + unit.learning_hours, 0)
    const count = optionalUnits.filter(unit => unit.selected).length
    setTotalLearningHours(total)
    setSelectedCount(count)
  }, [optionalUnits])

  const handleCheckboxChange = (id: number) => {
    setOptionalUnits(prevUnits => prevUnits.map(unit =>
      unit.id === id ? { ...unit, selected: !unit.selected } : unit
    ))
  }

  const { mutate: submitOptionalUnits } = useSubmitOptionalUnits({
    onSuccess: (data) => {
      replaceTopRoute(btecCourseManagementRoute)
    },
    onError: (error) => {
      console.error('Submission failed:', error)
    }
  })

  const handleSubmit = () => {
    const selectedUnits = optionalUnits.filter(unit => unit.selected).map(unit => ({ id: unit.id }))
    submitOptionalUnits({
      applicationId: applicationId ?? '',
      optionalUnits: selectedUnits,
    })
  }

  if (applicationsLoading || optionalUnitsLoading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>{applicationsLoading ? 'Fetching your applications...' : 'Fetching optional units...'}</Typography>
      </Box>
    )
  }

  if (applicationsIsError) {
    return <Typography>Error: {(applicationsError as ErrorWithMessage)?.message}</Typography>
  }

  if (optionalUnitsIsError) {
    return <Typography>Error loading optional units: {(optionalUnitsError as ErrorWithMessage)?.message}</Typography>
  }

  return (
    <AuthLayout appBar={<CamfedAppBar centralText={title} leftButton={<AppBarBackButton onClick={navigator.pop} />} />}>
      <Box sx={{ maxWidth: '1280px', width: '100%', my: 6, mx: 'auto', px: 4 }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            Optional Modules
          </Typography>
          <Typography variant="body1" sx={{ mt: 2, mb: 6 }}>
            Please select exactly 3 optional modules with learning hours equal to or over 50 hours.
          </Typography>
        </Box>
        <TableContainer sx={{ mb: 4 }}>
          <Table sx={{ borderTop: { xs: 'none', md: '1px solid #E0E0E0' }, borderLeft: { xs: 'none', md: '1px solid #E0E0E0' }, borderRight: { xs: 'none', md: '1px solid #E0E0E0' }, borderCollapse: 'separate' }}>
            <TableHead sx={{ display: { xs: 'none', md: 'table-header-group' } }}>
              <TableRow>
                <TableCell sx={{ borderRight: '1px solid #E0E0E0', height: '64px', padding: '12px', width: '5%' }}>Unit</TableCell>
                <TableCell sx={{ borderRight: '1px solid #E0E0E0', height: '64px', padding: '12px', width: '80%' }}>Title</TableCell>
                <TableCell sx={{ borderRight: '1px solid #E0E0E0', height: '64px', padding: '12px', width: '20%' }}>Learning Hours</TableCell>
                <TableCell sx={{ height: '64px', padding: '12px', width: '20%' }}>Select</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ display: { xs: 'flex', md: 'revert' }, flexDirection: 'column' }}>
              {optionalUnits.map((unit) => (
                <TableRow key={unit.id} sx={{ display: { xs: 'flex', md: 'table-row' }, flexDirection: 'column' }}>
                  <Hidden mdDown><TableCell sx={{ borderRight: '1px solid #E0E0E0', height: '64px', padding: '12px' }}>{unit.id}</TableCell></Hidden>
                  <TableCell sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: { xs: '1px solid #E0E0E0', md: 'none' }, borderRight: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, borderBottom: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, height: '64px', padding: '12px' }}>
                    {unit.title}
                    <Tooltip title={unit.description} arrow>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ display: { xs: 'flex', md: 'table-cell' }, alignItems: 'center', justifyContent: { xs: 'center', md: 'space-between' }, border: { xs: '1px solid #E0E0E0', md: 'none' }, borderRight: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, borderBottom: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, height: '64px', padding: '12px' }}>
                    {unit.learning_hours}
                    <Hidden mdUp>
                      <Box sx={{ marginLeft: '6px' }}>
                        Hours
                      </Box>
                    </Hidden>
                  </TableCell>
                  <TableCell sx={{ border: { xs: '1px solid #E0E0E0', md: 'none' }, borderRight: { xs: '1px solid #E0E0E0', md: 'none' }, borderBottom: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, height: '64px', padding: '12px', textAlign: 'center', marginBottom: { xs: '16px', md: '0' } }}>
                    <Checkbox
                      sx={{ padding: '0' }}
                      checked={unit.selected}
                      disabled={!unit.selected && selectedCount >= 3}
                      onChange={() => handleCheckboxChange(unit.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
              <TableRow sx={{ display: { xs: 'flex', md: 'table-row' }, flexDirection: 'column' }}>
                <Hidden mdDown><TableCell sx={{ borderRight: '1px solid #E0E0E0', height: '64px', padding: '12px' }}></TableCell></Hidden>
                <TableCell sx={{ border: { xs: '1px solid #E0E0E0', md: 'none' }, borderRight: { xs: '1px solid #E0E0E0', md: 'none' }, borderBottom: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, height: '64px', padding: '12px', textAlign: 'right', fontWeight: '600' }}>Total</TableCell>
                <TableCell sx={{ border: { xs: '1px solid #E0E0E0', md: 'none' }, borderRight: { xs: '1px solid #E0E0E0', md: 'none' }, borderBottom: { xs: '1px solid #E0E0E0', md: '1px solid #E0E0E0' }, height: '64px', padding: '12px', backgroundColor: totalLearningHours < 50 ? '#fdeded' : '#CCE8CD', color: totalLearningHours < 50 ? '#C10914' : '#1E4620' }}>
                  {totalLearningHours}
                </TableCell>
                <Hidden mdDown><TableCell sx={{ borderRight: '1px solid #E0E0E0', height: '64px', padding: '12px' }}></TableCell></Hidden>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          {totalLearningHours < 50 && (
            <Typography variant="body2" sx={{ color: '#C10914', mb: 4 }}>
              Please select a minimum of 50 learning hours.
            </Typography>
          )}
          {selectedCount === 3 && (
            <Typography variant="body2" sx={{ color: '#1E4620', mb: 4 }}>
              You can select a maximum of 3 optional modules.
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={totalLearningHours < 50}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </AuthLayout >
  )
}

export default observer(BTECOptionalModulesPage)